// -- privacy
// -- частично стянуто с https://toonme.app/privacy.html

.privacy__wrapper {
  width: 90%;
  max-width: 62rem;
  margin: 30px auto;

  p {
    text-align: left !important;
  }

  h1 {
    text-align: center;
    margin-bottom: 30px;
  }
}

.privacy__logo {
  font-family: 'Chewy';
  font-size: 30px;
  font-style: normal;
  line-height: 1;
  letter-spacing: 1px;
  color: #fff;
  border-radius: 44px;
  background-color: #3fb3f9;
  display: inline-block;
  padding: 7px 17px;
}

.privacy__page_title {
  font-size: 22px;
  font-weight: bold;
  line-height: 1.33;
  color: #3fb3f9;
  margin-bottom: 33px;
}

.privacy__paragraph {
  width: 100%;
  font-size: 1rem;
  line-height: 150%;
  font-weight: 300;
  letter-spacing: .03rem;
  margin-bottom: 2rem !important;
}

.privacy__list {
  list-style: disc;
  line-height: 150%;
  margin: 0 0 2rem 2rem;
}

.privacy__paragraph span {
  display: block;
  text-transform: uppercase;
  margin-bottom: 0.4rem;
}

.privacy__paragraph a {
  color: #3fb3f9;
  text-decoration: underline
}

.privacy__title {
  font-weight: 700;
}

@media (max-width: 700px) {
  .policy__wrapper {
    font-size: 14px
  }
}

@media (max-width: 500px) {
  .policy__wrapper {
    font-size: 13px
  }
}

@media (max-width: 400px) {
  .policy__wrapper {
    font-size: 12px
  }
}

@media (max-width: 370px) {
  .policy__wrapper {
    font-size: 11px
  }
}