@import "reset";
@import "fonts";
@import "common";

.app-design-v1 {
    @import "./v1/app_v1";
}
.app-design-v2 {
    @import "./v2/app_v2";
    &.app-locale {
        &--ru,
        &--de,
        &--fr,
        &--it {
            .btn-drop {
                font-size: 20px;
                span {
                    max-width: 80%;
                }
            }
        }
        &--ru {
            .main-page-title {
                font-family: inherit;
            }
            @media all and (max-width: 1380px) {
                .main-page {
                    .main-page-title {
                        font-size: 44px;
                    }
                }
            }
            @media all and (max-width: 900px) {
                .main-page {
                    .main-page-title {
                        font-size: 28px;
                    }
                }
            }
            @media all and (max-width: 880px) {
                .main-page-labs-info {
                    .main-page-title {
                        font-size: 24px;
                    }
                }
            }
            @media (max-width: 460px) {
                .app-design-v2 .main-page-content {
                    margin-bottom: 90px;
                }
            }
            @media all and (max-width: 380px) {
                .main-page {
                    .main-page-title {
                        font-size: 24px;
                    }
                }
            }
            @media all and (max-width: 330px) {
                .main-page {
                    .main-page-title {
                        font-size: 22px;
                    }
                }
            }
        }
    }
}

.app-design-v3 {
    @import "./v3/app_v3";
    &.app-locale--ru {
        .main-page-title {
            font-size: 20px;
        }
    }
}
