.modal-container {
    background-color: rgba(77,94,246,.86);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
}

.modal {
    border-radius: 40px;
    box-shadow: 0 8px 60px 0 rgba(43,43,97, .6);
    background-color: $color-white;
    box-sizing: border-box;
    max-width: 800px;
    width: 90%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    padding: 64px 24px;
    h3 {
        font-family: $font-secondary;
        font-size: 48px;
        line-height: 1.5;
        text-align: center;
        max-width: 560px;
        margin: 0 auto 24px;
    }
    p {
        font-size: 24px;
        line-height: 1.67;
        text-align: center;
        max-width: 560px;
        margin: 0 auto 40px;
    }
    .btns-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .btns-container_share {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-width: 560px;
    }
    button {
        font-family: $font-secondary;
        font-size: 24px;
        line-height: 1;
        letter-spacing: 0.96px;
        text-transform: uppercase;
        border-radius: 20px;
    }
    .btn-paint {
        height: 80px;
        padding: 0 33px;
        background-color: $color-secondary;
        color: $color-white;
    }
    .btn-empty {
        padding: 0 33px;
        background-color: transparent;
        color: $color-grey-dark;
    }
    .btn-cancel {
        color: $color-grey-dark;
        text-transform: uppercase;
        max-width: initial;
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 40px auto 0;
        svg {
            width: 24px;
            height: 24px;
            fill: $color-grey-dark;
            margin-right: 16px;
        }
        span {
            margin-top: 3px;
        }
    }
    .btn-download {
        display: flex;
        align-items: center;;
        text-align: left;
        color: $color-primary;
        text-transform: uppercase;
        background-color: transparent;
        margin: 44px auto 0;
        svg {
            width: 32px;
            height: 32px;
            margin-right: 16px;
        }
    }
    .btn-share {
        width: 80px;
        height: 80px;
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        border-radius: 50%;
        margin-right: 6px;
        svg {
            display: block;
            flex-shrink: 0;
            height: 40px;
            margin: 0 auto;
        }
    }
    .btn-share-insta {
        background-image: linear-gradient(45deg, #ffdd55 7%, #ff543e 50%, #c837ab 93%); 
        svg {
            width: 40px;
        }
    }
    .btn-share-snap {
        background-color: #ffdd0d;
        svg {
            width: 42px;
        }
    }
    .btn-share-fb {
        background-color: #1976d2;
        svg {
            width: 25px;
        }
    }
    .modal-text-processing {
        color: $color-grey-dark;
    }
    .icon-cat1 {
        position: absolute;
        top: -115px;
        right: 60px;
        height: 240px;
        z-index: -5;
    }
    .icon-cat2 {
        position: absolute;
        right: -110px;
        bottom: -60px;
        width: 220px;
        z-index: -5;
    }
    .icon-boom1 {
        position: absolute;
        left: -100px;
        top: -130px;
        width: 420px;
        z-index: -5;
    }
    .icon-boom2 {
        position: absolute;
        right: -55px;
        bottom: -45px;
        width: 420px;
        z-index: -5;
    }
    .check-foto-container {
        display: flex;
        justify-content: space-between;
        max-width: 560px;
        margin: 0 auto 40px;
    }
    .check-foto {
        width: 48%;
        border-radius: 16px;
        overflow: hidden;
        margin: 0;
        position: relative;
        border: solid 4px transparent;
        box-sizing: border-box;
        &.active {
            border-color: #7aafff;
        }
        &.not-active {
            &:after {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 20;
                background-color: rgba(232, 136, 21, 0.2);
            }
        }
        img {
            height: 100%;
            object-fit: cover;
            display: block;
            width: 100%;
            position: absolute;
            top: 0; 
            left: 0; 
            bottom: 0; 
            right: 0;
            z-index: 2;
        }
        &:before {
            content: "";
            display: block;
            width: 100%;
            padding-top: 100%;
        }
    }
}


.modal-remove-logo,
.modal-container-rate,
.modal-container-clones {
    .btn-empty {
        margin-top: 40px;
    }
}

.modal-container-download {
    .modal {
        p {
            margin-bottom: 24px;
        }
    }
}

.modal-container-snapchat {
    .btns-container {
        align-items: flex-start;
        max-width: 560px;
        margin: 0 auto;
    }
    .btn-download {
        color: $color-grey-dark;
        padding: 0;
        margin: 0;
        svg {
            fill: $color-grey-dark;
        }
    }
}

.modal-container-snapchat {
    background-color: $color-yellow;
}

@media all and (max-width: 900px) {
    .modal {
        border-radius: 24px;
        padding: 32px 24px;
        h3 {
            font-size: 28px;
            line-height: 1.43;
        }
        p {
            font-size: 16px;
            line-height: 1.5;
        }
        button {
            font-size: 16px;
            border-radius: 16px;
            padding: 0 20px;
        }
        .btn-paint {           
            height: 56px;
            padding: 0 16px;
        }
        .btn-cancel {
            margin-top: 24px;
            svg {
                width: 14px;
                height: 14px;
                margin-right: 8px;
            }
            span {
                margin-top: 2px;
            }
        }
        .btn-download {
            margin-top: 28px;
            svg {
                width: 24px;
                height: 24px;
                margin-right: 8px;
            }
        }
        .btn-share {
            width: 56px;
            height: 56px;
            svg {
                height: 24px;
            }
        }
        
        .btn-share-insta {
            svg {
                width: 24px;
            }
        }
        .btn-share-snap {
            svg {
                width: 25px;
            }
        }
        .btn-share-fb {
            svg {
                width: 13px;
            }
        }
        .icon-cat1 {
            top: -100px;
            right: 60px;
            height: 180px
        }
        .icon-cat2 {
            right: -84px;
            bottom: -60px;
            width: 170px;
        }
        .icon-boom1 {
            left: -60px;
            top: -60px;
            width: 190px;
        }
        .icon-boom2 {
            right: -35px;
            bottom: -25px;
            width: 230px;
        }
    }
    .modal-remove-logo,
    .modal-container-rate,
    .modal-container-clones { 
        .btn-empty {
            margin-top: 24px;
        }
    }
    .modal-container-snapchat {
        .btns-container {
            align-items: flex-start;
            .btn-download {
                margin: 0;
            }
        }
    }
    .modal-container-download {
        .modal {
            padding-top: 74px;
            p {
                margin-bottom: 16px;
            }
        }
    }
    .modal-container-rate {
        .modal {
            p {
                margin-bottom: 16px;
            }
        }
    }
}



// .modal { 
//     padding: 40px 24px;
//     border-radius: 24px;
//     box-shadow: 0 8px 60px 0 rgba(43,43,97, .6);
//     background-color: #ffffff;
//     box-sizing: border-box;
//     max-width: 800px;
//     width: 90%;
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%,-50%);
//     &-text-processing {
//         color: $color-grey-dark;
//         margin-top: 16px;
//         margin-bottom: 16px;
//     }
//     &-remove-logo {
//         p {
//             font-size: 16px;
//             line-height: 1.5;
//             text-align: center;
//             margin-bottom: 40px;
//         }
//         .btn-upload-foto {
//             width: 200px;
//             margin-bottom: 10px;
//         }
//     }
//     &-container {
//         background-color: rgba(77, 94, 246, 0.86);
//         backdrop-filter: blur(10px);
//         position: fixed;
//         top: 0;
//         left: 0;
//         width: 100%;
//         height: 100%;
//         z-index: 100;
//         &.snapchat {
//             background-color: $color-yellow;
//             h3 {
//                 font-family: $font-secondary;
//                 font-size: 28px;
//                 line-height: 1.43;
//                 text-align: center;
//                 margin-bottom: 24px;
//             }
//             p {
//                 margin-bottom: 36px;
//             }
//             .btn-share-snapchat {
//                 width: 200px;
//                 height: 48px;
//                 border-radius: 72px;
//                 background-color: #ffdd0d;
//                 font-weight: bold;
//                 text-transform: uppercase;
//                 display: block;
//                 margin: 0 auto;
//             }
//             .btn-back {
//                 font-family: $font-secondary;
//                 font-size: 16px;
//                 line-height: 1;
//                 text-align: left;
//                 color: $color-grey-dark;
//                 display: block;
//                 height: auto;
//                 width: auto;
//                 position: static;
//                 box-shadow: none;
//                 background-color: transparent;
//                 padding: 0;
//                 margin: 0;
//                 svg {
//                     width: 16px;
//                     height: 16px;
//                     fill: $color-grey-dark;
//                     margin-right: 8px;
//                 }
//                 span {
//                     margin-top: 2px;
//                 }
//             }
//             .check-foto-container {
//                 display: flex;
//                 justify-content: space-between;
//                 margin-bottom: 40px;
//             }
//             .check-foto {
//                 width: 48%;
//                 border-radius: 16px;
//                 overflow: hidden;
//                 margin: 0;
//                 position: relative;
//                 background-color: grey;
//                 img {
//                     height: 100%;
//                     object-fit: cover;
//                     display: block;
//                     width: 100%;
//                     position: absolute;
//                     top: 0; 
//                     left: 0; 
//                     bottom: 0; 
//                     right: 0;
//                     z-index: 2;
//                 }
//                 &:before {
//                     content: "";
//                     display: block;
//                     width: 100%;
//                     padding-top: 100%;
//                 }
//             }
//         }
//     }
    
//     &-download {
//         .modal {
//             padding-top: 90px;
//         }
//     }
//     h3 {
//         font-family: $font-secondary;
//         font-size: 28px;
//         line-height: 1.43;
//         text-align: center;
//         margin-bottom: 24px;
//     }
//     p {
//         font-size: 16px;
//         line-height: 1.5;
//         text-align: center;
//     }
//     .btn-transparent {
//         font-family: $font-secondary;
//         font-size: 14px;
//         color: $color-grey-dark;
//         text-transform: uppercase;
//         width: 200px;
//         height: 48px;
//         border-radius: 16px;
//         background-color: transparent;
//         display: block;
//         margin: 0 auto;
//     }
//     .btn-upload-foto {
//         &.small {
//             margin: 0;
//             max-width: 96px;
//         }
//         &.download {
//             font-family: $font-secondary;
//             font-size: 16px;
//             line-height: 1;
//             max-width: 100%;
//             height: auto;
//             display: flex;
//             align-items: center;;
//             letter-spacing: 0.64px;
//             text-align: left;
//             color: #2d3134;
//             text-transform: uppercase;
//             background-color: transparent;
//             margin: 24px auto 0;
//             svg {
//                 width: 24px;
//                 height: 24px;
//                 fill: #000;
//                 margin-right: 8px;
//             }
//         }
//     }
//     .btns-container_share {
//         display: flex;
//         align-items: center;
//         justify-content: space-around;
//         width: 100%;
//     }
//     .btn-share {
//         width: 47px;
//         height: 47px;
//         background-color: transparent;
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         flex-shrink: 0;
//         border-radius: 50%;
//     }
    
//     .btn-share-insta {
//         background-image: linear-gradient(45deg, #ffdd55 7%, #ff543e 50%, #c837ab 93%); 
//         svg {
//             display: block;
//             height: 24px;
//             width: 24px;
//             margin: 0 auto;
//         }
//     }
//     .btn-share-snap {
//         background-color: #ffdd0d;
//         svg {
//             display: block;
//             height: 24px;
//             width: 25px;
//             margin: 0 auto;
//         }
//     }
//     .btn-share-fb {
//         background-color: #1976d2;
//         svg {
//             display: block;
//             height: 24px;
//             width: 13px;
//             margin: 0 auto;
//         }
//     }
//     .btn-cancel {
//         font-family: $font-secondary;
//         line-height: 1;
//         letter-spacing: 0.64px;
//         text-align: center;
//         color: $color-grey-dark;
//         text-transform: uppercase;
//         max-width: initial;
//         background-color: transparent;
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         margin: 0 auto;
//         svg {
//             width: 14px;
//             height: 14px;
//             fill: $color-grey-dark;
//             margin-right: 8px;
//         }
//         span {
//             margin-top: 2px;
//         }
//     }
// }

// .icon-cat1 {
//     position: absolute;
//     top: -115px;
//     right: 60px;
//     height: 200px;
// }



